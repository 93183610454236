html {
    font-size: 62.5%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.9);
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  .calculator {
    border: 1px solid #ccc;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
  }

  .calculator-screen {
    width: 100%;
    height: 80px;
    border: none;
    background-color: #252525;
    color: #fff;
    text-align: right;
    padding-right: 20px;
    padding-left: 10px;
    font-size: 4rem;
  }

  button {
    height: 60px;
    font-size: 2rem!important;
  }

  .equal-sign {
    height: 70%;
    grid-area: 2 / 4 / 6 / 5;
  }
  .last-clear{
    grid-area: 5 / 4 / 6 / 5;
  }
  .calculator-keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }